import React from "react";
import ReactDOM from "react-dom/client";
import Login from "./Login";
import Site from "./Site";
import "./styles.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const origin = new URL(window.location.href);
console.dir(origin);

let whereAmI = "";
switch (origin.pathname) {
    case "/site-a":
        whereAmI = "sso-demo-a.com";
        break;
    case "/site-b":
        whereAmI = "sso-demo-b.com";
        break;
    default:
        whereAmI = origin.hostname.split(".").slice(-2).join(".");
}

let ret = <></>;
switch (whereAmI) {
    case "sso-demo-a.com":
        ret = <Site />;
        break;
    case "sso-demo-b.com":
        ret = <Site />;
        break;
    default:
        ret = <Login />;
}

root.render(ret);
