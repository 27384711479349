import { OidcClient, OidcClientSettings, SigninResponse, WebStorageStateStore } from "oidc-client-ts";
import React, { useEffect, useRef, useState } from "react";
import "./styles.css";

const Site: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const debug = urlParams.get("debug");
    // Standard OIDC Client Settings
    const statePrefix = window.location.pathname.replace(/\//g, "_");
    const stateUserKey = `${statePrefix}_user`;

    const oidcSettings = useRef<OidcClientSettings>({
        authority: "", // Will be set using state
        client_id: "", // Will be set using state
        redirect_uri: window.location.href, // Where to redirect after login
        post_logout_redirect_uri: window.location.origin + window.location.pathname, // Where to redirect after logout
        response_type: "code",
        scope: "openid email roles descope.custom_claims offline_access",
        stateStore: new WebStorageStateStore({
            store: window.localStorage,
            prefix: statePrefix,
        }),
        loadUserInfo: true,
        fetchRequestCredentials: "same-origin",
    });

    const client = useRef<OidcClient>(new OidcClient(oidcSettings.current));

    const [link, setLink] = useState("");
    const [logData, setLogData] = useState("");
    const [descope, setDescope] = useState(window.localStorage.getItem("descope") === "true");
    const [client_id, setClientId] = useState(window.localStorage.getItem("clientId") || "");
    const [authority, setAuthority] = useState(window.localStorage.getItem("authority") || "https://api.descope.com");
    const [redirect, setRedirect] = useState(window.localStorage.getItem("redirect") === "true");
    const [user, setUser] = useState<SigninResponse>(
        JSON.parse(window.localStorage.getItem(stateUserKey) || "{}") ?? ({} as SigninResponse)
    );

    useEffect(() => {
        if (!debug) {
            setRedirect(true);
            setDescope(true);
        }

        console.dir(JSON.stringify({ authority, client_id, descope, redirect }));
        window.localStorage.setItem("descope", descope ? "true" : "false");
        window.localStorage.setItem("redirect", redirect ? "true" : "false");
        window.localStorage.setItem("clientId", client_id);
        window.localStorage.setItem("authority", authority);
        window.localStorage.setItem(stateUserKey, JSON.stringify(user || {}));

        let fixedUrl = authority;
        if (authority && client_id) {
            try {
                fixedUrl = new URL(fixedUrl).href;
                if (descope) {
                    fixedUrl = new URL(client_id, authority).href; // append client id to authority
                }
            } catch (e: unknown) {
                err(e as Error);
            }
        }

        client.current = new OidcClient({ ...oidcSettings.current, authority: fixedUrl, client_id });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect, authority, client_id, descope, user, statePrefix, stateUserKey]);

    // Log errors
    const err = async (err: Error) => {
        console.error(err);
        setLogData(JSON.stringify({ message: err.message, name: err.name }, null, 4));
    };

    // Create signin request
    const signin = () => {
        client.current
            .createSigninRequest({})
            .then(({ url, state }) => {
                url += `&origin=` + encodeURIComponent(window.location.href);
                setLink(url);
                console.log(state);
                if (redirect) window.location.href = url;
            })
            .catch(err);
    };

    // Process signin response
    const processSigninResponse = () => {
        client.current.processSigninResponse(window.location.href).then(_finishLogin).catch(err);
    };

    // Refresh token
    const refreshToken = () => {
        if (user && user.refresh_token)
            client.current
                .useRefreshToken({ state: { refresh_token: user.refresh_token, data: null, ...user } })
                .then(_finishLogin)
                .catch(err);
        else err(new Error("No user to refresh"));
    };

    // handle signin response
    const _finishLogin = (user: SigninResponse) => {
        setLogData(JSON.stringify(user, null, 4));
        setUser(user);
    };

    // Logout (this will log out all user sessions)
    const logout = () => {
        if (user)
            client.current
                .createSignoutRequest({
                    state: user,
                    id_token_hint: user.id_token,
                })
                .then(({ url, state }) => {
                    setLink(url);
                    console.dir(state);
                    setUser({} as SigninResponse);
                    if (redirect) window.location.replace(url);
                })
                .catch(err);
        else err(new Error("No user to logout"));
    };

    // revoke token
    const revokeToken = () => {
        if (user && user.refresh_token)
            client.current
                .revokeToken(user.refresh_token, "refresh_token")
                .then(() => {
                    console.log("token revoked");
                    setLogData("token revoked");
                })
                .catch((err) => {
                    console.error(err);
                    setLogData(JSON.stringify({ message: err.message, name: err.name }, null, 4));
                });
        else err(new Error("No user to revoke"));
    };

    const Debug = (
        <div>
            <h2>{window.location.origin}</h2>
            <label>
                Authoriry:
                <input type="text" onChange={(e) => setAuthority(e.target.value)} value={authority} size={100} />
            </label>
            <br />
            <label>
                Client ID:
                <input type="text" onChange={(e) => setClientId(e.target.value)} value={client_id} size={100} />
            </label>
            <br />
            <label>
                Descope:
                <input type="checkbox" onChange={() => setDescope(!descope)} checked={descope} />
            </label>
            <br />
            <button onClick={() => signin()}>1. signin</button>
            <button onClick={() => processSigninResponse()}>2. process signin response</button>
            <button onClick={() => refreshToken()}>3. refresh token</button>
            <button onClick={() => revokeToken()}>4. revoke current session</button>
            <button onClick={() => logout()}>4. logout all sessions</button>
            <br />
            <p>
                <label>
                    <input type="checkbox" onChange={() => setRedirect(!redirect)} checked={redirect} />
                    Follow redirects
                </label>
            </p>
            <p>
                <a href={link}>{link}</a>
            </p>
            <pre>{logData}</pre>
        </div>
    );

    useEffect(() => {
        if (urlParams.get("code") && urlParams.get("state")) {
            processSigninResponse();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Simple = (
        <>
            <h2>{window.location.origin}</h2>
            {user && user.profile ? (
                <>
                    <h2>Welcome {user.profile.email}</h2>
                    <button onClick={() => logout()}>Logout</button>
                </>
            ) : (
                <>
                    <button onClick={() => signin()}>Sign In</button>
                    &nbsp;
                    <button onClick={() => signin()}>Sign Up</button>
                </>
            )}
            <pre>{logData}</pre>
        </>
    );

    if (!client_id && !debug)
        return (
            <>
                Please use <a href="?debug=1">debug mode</a> to set params
            </>
        );
    if (debug) {
        return Debug;
    } else {
        return Simple;
    }
};

export default Site;
