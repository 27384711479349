import "./styles.css";

import { AuthProvider, SignUpOrInFlow, useDescope, useSession, useUser } from "@descope/react-sdk";
import React, { useEffect, useState } from "react";

const baseUrl = window.localStorage.getItem("baseUrl") || "https://api.super-sso.com";

const OidcLoginHandler: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const { isAuthenticated, isSessionLoading } = useSession();
    const { user, isUserLoading } = useUser();
    const { logout } = useDescope();
    const [redirect, setRedirect] = useState(window.localStorage.getItem("redirect") === "true");

    const state = urlParams.get("state_id") ?? window.localStorage.getItem("state");
    if (state) window.localStorage.setItem("state", state || "");

    // Save redirect state to local storage
    useEffect(() => {
        window.localStorage.setItem("redirect", redirect ? "true" : "false");
    }, [redirect]);

    // Hide page while loading
    if (isSessionLoading || isUserLoading) return <>Loading...</>;

    // Handle logged in state
    if (isAuthenticated && user) {
        const redirectURL = `${baseUrl}/oauth2/v1/finish-authorize?state_id=${state}`;
        if (redirect) {
            window.location.href = redirectURL;
            return <></>;
        }
        return (
            <>
                <pre>{JSON.stringify(user, null, 4)}</pre>
                <label>
                    <input type="checkbox" onChange={() => setRedirect(!redirect)} checked={redirect} />
                    Follow redirects
                </label>
                <a href={redirectURL}>{redirectURL}</a>
                <button onClick={() => logout()}>Logout</button>
            </>
        );
    } else {
        // Show login flow if not logged in
        return <>{children}</>;
    }
};

const Login: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("projectId") || localStorage.getItem("projectId");
    if (!projectId)
        return (
            <p>Missing projectId. Please set projectId URL parameter (ex. {window.location.origin}/?projectId=XXXX)</p>
        );
    if (projectId !== localStorage.getItem("projectId")) {
        localStorage.removeItem("DSR");
        localStorage.removeItem("DS");
        localStorage.setItem("projectId", projectId);
    }

    return (
        <AuthProvider projectId={projectId} baseUrl={baseUrl}>
            <OidcLoginHandler>
                <SignUpOrInFlow />
            </OidcLoginHandler>
        </AuthProvider>
    );
};

export default Login;
